<template>
  <div class="orderTalk">
    <el-button
      type="primary"
      :size="size"
      round
      @click="openDialog"
      v-if="!type"
      >预约面试</el-button
    >
    <span @click="openDialog" v-else><slot name="btn"></slot></span>
    <component
      ref="orderAgenda"
      :is="orderComponents"
      :orderVisible="orderVisible"
      @orderVisibleShow="orderVisibleShow"
      @dataChange="sendAgenda"
    ></component>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="400px"
      append-to-body
    >
      <span class="dialogTxt">{{ dialogTxt }}？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="orderAgenda(orderVal)"
          size="small"
          :loading="orderLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import orderFreeDialog from "~/baseComponents/orderFreeDialog";
import orderDialog from "~scb/components/button/orderDialog";
import { secondFormat } from "~/basePlugins/filters";
export default {
  components: {
    orderFreeDialog: orderFreeDialog,
    orderDialog: orderDialog,
  },
  props: {
    size: {
      type: String,
      default: "mini",
    },
    in_companyId: {
      //接收面试公司id
      type: Number,
      default: null,
    },
    in_userId: {
      //接收面试公司负责人id
      type: Number,
      default: null,
    },
    position_id: {
      //职位id
      // type: Number || String,
      // default: null,
    },
    invitationType:{
      type:String,
      default: '1',
    }
  },
  data() {
    return {
      type: false,
      orderComponents: null,
      orderVisible: false,
      dialogVisible: false,
      orderLoading: false,
      orderVal: {},
      dialogTxt: "",
    };
  },
  methods: {
    orderVisibleShow(val) {
      if (val) {
        this.orderVisible = true;
      } else {
        this.orderVisible = false;
      }
    },
    openDialog() {
      this.INFO_MATION().then((e) => {
        if (e === false) {
          this.orderVisible = true;
          this.$refs.orderAgenda.hide();
        } else if (e == "login") {
          this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        }
      });
    },
    // 发送邀请
    async sendAgenda(val) {
      let params;
      if (this.orderComponents == "orderFreeDialog") {
        let obj = {
          meeting_status: 2,
          send_user_id: this.USER_INFO.id,
          in_user_id: this.in_userId,
          in_company_id: this.in_companyId,
          // send_company_id: this.USER_INFO.company_id,
          daily_source: 1,
          // related_id: this.MEETING_ID,
          status: 1,
          position_apply_status: 3, //求职者预约2,招聘者预约3
          position_id: this.position_id,
          apply_type: 1, //求职者预约1,招聘者预约2
          chat_type: 2,
          user_id: this.USER_INFO.id, //投递者id
          invitation_type: 1,
          stem_from:2,
          related_id: this.USER_INFO.company_id||0
        };
        params = Object.assign(obj, val);
      } else {
        params = {
          meeting_status: 1,
          meeting_id: this.MEETING_ID,
          agenda_id: val.agenda_id,
          send_postscript: val.send_postscript,
          send_user_id: this.USER_INFO.id,
          in_user_id: this.in_userId,
          in_company_id: this.in_companyId,
          // send_company_id: this.USER_INFO.company_id,
          room_type: 1,
          status: 1,
          position_apply_status: 3, //求职者预约2,招聘者预约3
          position_id: this.position_id,
          apply_type: 1, //求职者预约1,招聘者预约2
          chat_type: 1,
          user_id: this.USER_INFO.id, //投递者id
          invitation_type: 1,
        };
        // this.orderAgenda(obj);
      }
      params.source= this.PJSource
      params.invitation_type=this.invitationType;
      this.orderStatus(params);
    },
    // 预约状态
    async orderStatus(val) {
      this.orderVal = val;
      let params = {
        // meeting_id: this.MEETING_ID,
        meeting_status: val.meeting_status,
        user_type: 1,
        invite_status: 1,
        user_id: this.USER_INFO.id,
        in_user_id: this.in_userId,
        position_id: val.position_id,
        related_id: this.USER_INFO.company_id||0,
        daily_source:2
      };
      if (val.meeting_status == 2) {
        params.start_time = val.start_time;
        params.end_time = val.end_time;
      } else {
        params.agenda_id = val.agenda_id;
      }
      params.source=this.PJSource
      let result = await this.$store.dispatch(
        "API_position/orderStatus",
        params
      );
      if (result.success) {
        if (JSON.stringify(result.data) == "{}") {
          this.orderAgenda(val);
        } else if (result.data.status) {
          // result.data.status = 6;
          switch (result.data.status) {
            case 1:
              this.dialogVisible = true;
              this.dialogTxt = "您在该时间段已预约面试，是否继续发送新面试申请";
              break;
            case 3:
              this.dialogVisible = true;
              this.dialogTxt = "您在该时间段已预约面试，是否继续发送新面试邀请";
              break;
            case 5:
              this.dialogVisible = true;
              let timedate = `${secondFormat(
                  result.data.info.start_time,
                  "LLL"
              )}-${secondFormat(result.data.info.end_time, "T")}`;
              this.dialogTxt = `你已申请 ${timedate} 该职位的面试，是否要变更面试时间`;
              break;
            case 6:
              this.dialogVisible = true;
              let timer = `${secondFormat(
                  result.data.info.start_time,
                  "LLL"
              )}-${secondFormat(result.data.info.end_time, "T")}`;
              this.dialogTxt = `招聘单位已接受你的${timer} 面试申请，是否要取消该时间面试，重新申请面试时间`;
              break;
            case 7:
              this.dialogVisible = true;
              let timedates = `${secondFormat(
                  result.data.info.start_time,
                  "LLL"
              )}-${secondFormat(result.data.info.end_time, "T")}`;
              this.dialogTxt = `您已邀请对方在 ${timedates} 面试，是否要变更面试时间`;
              break;
            case 8:
              this.dialogVisible = true;
              let timers = `${secondFormat(
                  result.data.info.start_time,
                  "LLL"
              )}-${secondFormat(result.data.info.end_time, "T")}`;
              this.dialogTxt = `对方已接受 ${timers} 的面试邀请，是否要取消该面试，重新安排面试时间`;
              break;
            case 9:
              this.$message.warning({
                message: "重复预约",
                customClass: "zZindex",
              });
              break;
            default:
          }
          this.$refs.orderAgenda.loadingChange();
        } else {
        }
      }
    },
    // 面试预约
    async orderAgenda(params) {
      if (this.dialogVisible) {
        this.orderLoading = true;
      }
      params.source = this.PJSource
      let result = await this.$store.dispatch(
        "API_position/jobAppointment",
        params
      );
      if (result.success && typeof result.info != "string") {
        this.$message.success({ message: "发送成功", customClass: "zZindex" });
        this.orderVisible = false;
        this.$emit("successChange");
        this.$refs.orderAgenda.loadingChange();
      } else {
        this.$message.error({ message: "发送失败", customClass: "zZindex" });
        this.$refs.orderAgenda.loadingChange();
      }
      if (this.dialogVisible) {
        this.dialogVisible = false;
        this.orderLoading = false;
      }
    },
  },
  mounted() {
    this.type = this.$scopedSlots.$hasNormal;
    // if (this.MEETING_STATUS == 3) {
      this.orderComponents = "orderFreeDialog";
    // } else {
    //   this.orderComponents = "orderDialog";
    // }
  },
};
</script>
<style lang="less" scoped>
.orderTalk {
  display: inline-block;
}
.dialogTxt {
  font-size: 14px;
  line-height: 24px;
}
</style>
