<template>
  <div class="submitResume">
    <el-button round @click="sumbit" :size="size" v-if="!type"
      >投递简历</el-button
    >
    <span @click="sumbit" v-else><slot name="btn"></slot></span>
    <div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="300px"
        close-on-click-modal
      >
        <span>请确认是否投递？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="loading"
            @click="handleResume"
            >投 递</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position_id: {
      type: Number | String,
      default: null,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      type: false,
      loading: false,
      dialogVisible: false,
    };
  },
  mounted() {
    this.type = this.$scopedSlots.$hasNormal;
  },
  methods: {
    // 投递简历
    sumbit() {
      this.INFO_MATION().then((e) => {
        if (e === false) {
          this.dialogVisible = true;
        } else if (e == "login") {
          this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        }
      });
    },
    async handleResume() {
      this.loading = true;
      let params = {
        position_id: this.position_id,
        user_id: this.$store.state.baseStore.userId,
        status: 0,
        // name:this.USER_INFO.name_zh||this.USER_INFO.name_en,
        chat_type:2,
        source:this.PJSource
      };
      let res = await this.$store.dispatch(
        "API_position/userJobPositionSave",
        params
      );
      if (res.success) {
        this.loading = false;
        this.$message.success({
          message: "投递成功",
          customClass: "zZindex",
        });
        this.$emit("successChange");
      } else if (res.errorcode == 1000) {
        this.loading = false;
        this.$message({
          message: "已投递",
          type: "warning",
          customClass: "zZindex",
        });
      } else {
        this.loading = false;
        this.$message.error({
          message: "投递失败",
          customClass: "zZindex",
        });
      }
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.submitResume {
  display: inline-block;
  /deep/.el-dialog__body {
    padding: 20px 30px;
  }
}
</style>